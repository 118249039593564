@media screen {
	.menuList {
		@apply tw-border-t-gray tw-bg-white tw-text-gray-100 tw-m-0 tw-list-none;
		padding: 5px 0 10px;

		&:first-child {
			@apply tw-border-0 tw-m-0;
			padding-top: 10px;
			padding-bottom: 5px;

			li:first-child a {
				padding-top: 15px;
			}
		}

		li {
			margin: 0;
			padding: 0;
			position: relative;

			a:link,
			a:visited,
			button {
				@apply tw-font-semibold tw-text-gray-100 tw-no-underline tw-block tw-overflow-hidden;

				font-size: 17px;
				padding: 10px;

				&.clearance {
					@apply tw-text-red;
				}
				.highlight {
					@apply tw-text-blue;
				}
				&.back-button {
					@apply tw-font-semibold;
					> span {
						float: left;

						&:first-child {
							margin-right: 7px;
							line-height: 20px;
						}
					}
				}
			}
			.link-list-heading {
				@apply tw-font-semibold tw-inline-block;
				font-size: 17px;
				padding: 15px 10px 5px;
				margin-bottom: 2px;
			}
			a:after {
				content: '';
			}
		}
		&.link-list-top {
			@apply tw-border-0;

			li {
				margin-bottom: 5px;

				a, button {
					background-position-y: center;
					background-repeat: no-repeat;
					padding-left: 45px;
				}

				&.link-item-dev-beam {
					@apply tw-relative;
					top: -9px;
					.link-item-dev-beam-button {
						font-size: 17px;
						padding: 20px 10px 20px 45px;
						background-repeat: no-repeat;
						background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_mobile_hammer);
						background-position-x: 7px;
						background-position-y: 50%;
						background-size: 30px;
					}
					:global(.dev-beam-back) .link-item-dev-beam-button {
						background-image: none;
						padding-left: 10px;
					}
				}
				&.link-item-stores {
					a {
						background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_stores);
						background-position-x: 7px;
						background-size: 30px;
					}
				}
				&.link-item-chat {
					button, div {
						font-size: 17px;
						background-image: url(//rnb.scene7.com/is/content/roomandboard/chat);
						background-position-x: 7px;
						background-size: 30px;
						background-position-y: center;
						background-repeat: no-repeat;
						padding-left: 45px;
					}
				}

				&.link-item-wishlist, &.link-item-favorites {
					a, button {
						background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_wishlist);
						background-position-x: 10px;
						background-size: 25px;

						:global .wishlist-items-summary-count, :global .favorites-items-summary-count {
							@apply tw-absolute tw-text-white tw-block tw-border tw-border-solid tw-border-white tw-text-center tw-font-bold
							tw-text-3xs tw-tracking-normal;
							background-color: theme('colors.gray.DEFAULT');
							bottom: 5px;
							left: 5px;
							width: 19px;
							height: 19px;
							line-height: 18px;
							border-radius: 50%;
						}
					}
				}
			}
		}

		&.link-list-icon {
			padding-bottom: 5px;

			li {
				a {
					background-position-y: center;
					background-repeat: no-repeat;
				}

				&.link-item-gallery a {
					background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_gallery);
					background-position-x: 5px;
					background-size: 35px;
					padding-left: 50px;
				}

				&.link-item-favorites a {
					background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_wishlist);
					background-position-x: 10px;
					background-size: 25px;
					padding: 8px 50px;
				}

				&.link-item-ideas-and-advice a {
					background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_bulb);
					background-position-x: 10px;
					background-size: 35px;
					padding: 8px 55px;
				}

				&.link-item-business-interiors a {
					background-image: url(//rnb.scene7.com/is/content/roomandboard/icon_business-interiors);
					background-position-x: 10px;
					background-size: 50px;
					padding-left: 70px;
				}

				&.link-item-sustainability a {
					background-image: url(//rnb.scene7.com/is/content/roomandboard/sustainability-leaf);
					background-position-x: 10px;
					background-size: 35px;
					padding: 8px 55px;
				}
			}
		}
		&.link-list-small {
			@apply tw-border-0 tw-p-0;

			li {
				a:link,
				a:visited {
					@apply tw-font-normal;
				}
			}
		}
		&.clearance {
			li {
				h3 {
					@apply tw-text-red;
				}

				ul li {
					a:link, a:visited {
						@apply tw-text-red;
					}
				}
			}
		}
		&.subMenu-list {
			&:first-child {
				padding-bottom: 5px;

				li:first-child a {
					padding-top: 15px;
				}
			}
		}
	}
	.link-item-dev-beam {
		@apply tw-m-0 #{'!important'};
	}
}
