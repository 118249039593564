/*-----------------------------------------------------------
		Utilities for standalone adjustments. These are not BEM
		components and thus, should be prefixed with 'u-' to
		denote it is a utility class to adhere to SUIT CSS.

		Also unlike components, this file includes mobile
		overrides contrary to a separate file. Most of the time,
		these will be using !important to ensure these styles will
		be applied.

		TABLE OF CONTENTS:

		TYPOGRAPHY
		BORDERS
		SCROLL BARS
	-----------------------------------------------------------*/

@media all {

	/*-----------------------------------------------------------
		TYPOGRAPHY
	-----------------------------------------------------------*/

	.u-noListStyle {
		list-style: none !important;
	}
	.u-lsn {
		list-style: none;
		margin-left: 0;
	}

	/*-----------------------------------------------------------
		BORDERS
	-----------------------------------------------------------*/

	a.u-hoverOutline {
		display: inline-block;
	}
	.u-hoverOutline:hover,
	.u-hoverOutline:focus {
		outline: 1px solid theme('colors.blue.DEFAULT');
		outline-offset: 2px;
	}
	.u-focusOutline:focus,
	.u-focusSiblingOutline:focus + * {
		outline: 1px solid theme('colors.blue.DEFAULT');
		outline-offset: 2px;
	}
}