@media all {

	.Card.Card--youTubeVideo {
		&:not(.column) {
			padding-top: 0;
		}
		.Card-content {
			.YouTube-thumbnail-holder {
				position: relative;
				margin-bottom: 5px;

				.playButton {
					width: 20%;
					position: absolute;
					top: 30%;
					left: 50%;
					margin-left: -10%;
					opacity: .8;
				}
			}
		}
	}
}
