// It is preferred if you add the appropriate "print-only / screen-only" classes to the markup directly rather than add it here.
// Reserve that for markup that cannot be directly accessed (i.e. third-party scripts / HTML)
@media screen {
	.print-only {
		display: none !important;
	}
}
@media print {
	* {
		// This prevents weird issues between Chrome print emulation and print dialog.
		-webkit-transition: none !important;
		transition: none !important;
	}

	.screen-only {
		display: none !important;
	}

	ul {
		line-height: normal;
		margin-left: 0 !important;
	}
	//Foundtion override
	a[href]:after {
		content: "";
	}
	strong {
		font-weight: bold;
	}
	div.page {
		> #content {
			display: block;
		}

		> header, .bumpers > header, > #react-header > header, .bumpers > #react-header > header {
			display: block;

			> * {
				display: none;
			}

			> #logo {
				@apply tw-flex tw-flex-row tw-justify-between tw-text-left tw-border-b tw-border-black tw-mb-3;
			}
		}
	}
	#h_search, .top-links {
		@apply tw-invisible tw-w-0 tw-hidden;
	}
	#logo {
		@apply tw-flex tw-justify-between tw-w-full tw-pb-4;

		a img {
			display: block;
			width: 275px;
			height: 45px;
			margin: 0 auto;
		}

		.here-to-help {
			@apply tw-block tw-text-right tw-text-gray-100 tw-text-sm tw-capitalize tw-mt-1;
			letter-spacing: .25px;
		}
	}
	#footer {
		display: block;
		border-top: none;

		padding: 0;

		.footerMenu, .footer-our-promise, .footer-links {
			display: none;
		}
	}
	.print-product-title {
		@apply tw-uppercase tw-text-brown tw-font-bold tw-text-3xs tw-leading-5 tw-pb-0;
	}
	.print-custom-product-policy {
		margin-bottom: 10px;
		border-bottom: solid 1px;
		padding-bottom: 15px;
	}
	.u-showForMobile {
		display: none !important;
	}
	// Make buttons look like anchors.
	.button-as-anchor {
		@apply tw-border-none tw-bg-none tw-text-blue tw-underline tw-inline tw-m-0 tw-p-0 tw-cursor-pointer;
		font-size: inherit;
		font-family: inherit;

		span {
			font-family: inherit;
			font-size: inherit;
			color: inherit;
		}
	}
	.responsive-masthead-container {
		margin: 10px 0;
	}
	.responsive-masthead-title {
		font-size: 42px;
		line-height: 1.2;
	}
	.review-average {
		margin: 0 0 0 5px;
	}
	.media-viewer--detail {
		margin: 10px 0;

		.slick-track {
			height: auto !important;
		}
		.slick-cloned {
			display: none !important;
		}
		.selected-media-caption {
			display: none !important;
		}
	}
	.dimensions-title {
		font-size: 24px !important;
	}
}
