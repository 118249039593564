@mixin ButtonArrow {
	@apply tw-bg-white tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-gray-100 tw-border;
	display: flex;
	align-items: center;
	justify-content: center;

	&:focus, &:hover {
		@apply tw-bg-gray-100;
		&:before {
			@apply tw-border-white;
		}
	}

	&:focus {
		outline: 1px dotted #757575;
		outline-offset: 2px;
		box-shadow: 0 0 1px #ccc;
	}

	&:disabled {
		@apply tw-border-gray-400;
		&:before {
			@apply tw-border-gray-400;
		}
		&:hover {
			@apply tw-border-gray-400 tw-bg-white;
		}
	}

	&.ButtonArrowTransparent:not(:focus):not(:hover) {
		@apply tw-bg-transparent tw-border-none;
	}
}

.ButtonArrowDown, .ButtonArrowLeft, .ButtonArrowRight, .ButtonArrowUp {
	@include ButtonArrow();
}

.ButtonArrowSmall {
	height: 24px !important;
	width: 24px !important;

	&:before {
		@apply tw-border-b tw-border-r tw-transform;
		content: '';
		height: 9px;
		width: 9px;
	}

	&.ButtonArrowDown {
		&:before {
			transform: rotate(45deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	&.ButtonArrowLeft {
		&:before {
			transform: rotate(135deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	&.ButtonArrowRight {
		&:before {
			transform: rotate(-45deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	&.ButtonArrowUp {
		&:before {
			transform: rotate(225deg) translateX(-1px) translateY(-1px) !important;
		}
	}
}

.ButtonArrowMedium {
	height: 34px !important;
	width: 34px !important;

	&:before {
		@apply tw-border-b tw-border-r tw-transform;
		content: '';
		height: 14px;
		width: 14px;
	}

	&.ButtonArrowDown {
		&:before {
			transform: rotate(45deg) translateX(-2px) translateY(-2px) !important;
		}
	}

	&.ButtonArrowUp {
		&:before {
			transform: rotate(225deg) translateX(-2px) translateY(-2px) !important;
		}
	}

	&.ButtonArrowLeft {
		&:before {
			transform: rotate(135deg) translateX(-2px) translateY(-2px) !important;
		}
	}

	&.ButtonArrowRight {
		&:before {
			transform: rotate(-45deg) translateX(-2px) translateY(-2px) !important;
		}
	}
}
