@media all {
	.InnerGutter-2 {
		&:nth-of-type(2n+1) {
			padding-right: 1%;
			padding-left: 0;
		}
		&:nth-of-type(2n+2) {
			padding-left: 1%;
			padding-right: 0;
		}
	}

	.InnerGutter-3 {
		&:nth-of-type(3n+1) {
			padding-right: 1%;
			padding-left: 0;
		}
		&:nth-of-type(3n+2) {
			padding: 0 .5%;
		}
		&:nth-of-type(3n+3) {
			padding-left: 1%;
			padding-right: 0;
		}
	}
	.mobile {
		.InnerGutter-3 {
			&:nth-of-type(3n+1) {
				padding-left: .5%;
			}
			&:nth-of-type(3n+3) {
				padding-right: .5%;
			}
		}
	}
	.InnerGutter-4 {
		text-align: center;
		&:nth-of-type(4n+1) {
			padding-right: .5%;
			padding-left: 0;
		}
		&:nth-of-type(4n+2) {
			padding-right: .5%;
			padding-left: .5%;
		}
		&:nth-of-type(4n+3) {
			padding-right: .5%;
			padding-left: .5%;
		}
		&:nth-of-type(4n+4) {
			padding-left: .5%;
			padding-right: 0;
		}
	}
}
