
.Card.Card--fullScreenWidth, .Card.Card--fullScreenWidth__sm {
	@apply tw-w-screen;
	margin-left: 50%;
	transform: translateX(-50%);
	.Card--fullScreenWidth__img {
		width: 100%;
	}
	&.Card--fullScreenWidth__textOverlay {
		.Card--fullScreenWidth__text {
			position: absolute;
		}
	}
}

@screen md {
	.Card.Card--fullScreenWidth__sm {
		@apply tw-w-full;
		margin-left: 0;
		transform: none;
	}
}