@import 'css/lib/tailwind-custom/triangle';

/*-----------------------------------------------------------
	TABLE OF CONTENTS

	Tabccordion
	    Accordion mixin
		Tabccordion default reponsive, tabs desktop, accordion mobile
		Tabccordion accordion only
		Tabccordion details (mobile checkout order summary)
		Tabccordion details large (Engage cart interstitial)
		Tabccordion orderSummary
		Tabccordion overrides
-----------------------------------------------------------*/

/*-----------------------------------------------------------
	Accordion mixin
-----------------------------------------------------------*/

@mixin tabccordion-accordion {
	.Tabccordion-list {
		display: none;
	}
	.Tabccordion-contentContainer {
		@apply tw-p-0 tw-border-0;
		.Tabccordion-accordion {
			@apply tw-text-lg tw-font-light tw-grid tw-grid-flow-col tw-gap-3 tw-justify-between tw-py-6 tw-w-full tw-text-left;
			align-items: center;
			background: #fff;
			border-top: 1px solid theme('colors.gray.DEFAULT') !important;
			border-left: none;
			border-right: none;
			min-height: 80px;
			padding: 15px 10px 15px 0;

			.Tabccordion-status {
				height: 20px;
				position: relative;
				width: 20px;
				@screen md {
					height: 24px;
					width: 24px;
				}

				&::before,
				&::after {
					background: theme('colors.gray.300');
					content: '';
					margin: 0;
					position: absolute;
				}

				&::before {
					height: 20px;
					transform: translate(10px,  0);
					width: 1px;
					@screen md {
						height: 24px;
						transform: translate(12px,  -1px);
					}
				}

				&:after {
					height: 1px;
					transform: translate(0, 9px);
					width: 20px;
					@screen md {
						transform: translate(0, 11px);
						width: 24px;
					}
				}
			}

			&:focus {
				outline: none;
				box-shadow: none;

				.Tabccordion-status {
					outline: 1px dotted theme('colors.gray.300');
					outline-offset: 2px;
				}
			}

			&.isActive {
				color: theme('colors.gray.DEFAULT');
				.Tabccordion-status {
					margin-top: 0;
					display: block;

					&:before {
						transform: rotate(45deg) translate(7px, -7px);
						@screen md {
							transform: rotate(45deg) translate(8px, -9px);
						}
					}

					&:after {
						transform: rotate(45deg) translate(7px, 7px);
						@screen md {
							transform: rotate(45deg) translate(8px, 7px);
						}
					}
				}
			}

			&.isDisabled {
				> * {
					@apply tw-opacity-40;
				}
			}
		}

		.Tabccordion-content {
			overflow: hidden;
		}
	}
}

/*-----------------------------------------------------------
	Tabccordion responsive, tabs desktop, accordion mobile
-----------------------------------------------------------*/

.Tabccordion-wrapper {
	display: block;

	&.no-border {
		.Tabccordion-content {
			&, &.isActive {
				border: none;
			}
		}
	}

	/* List is the tabs */
	.Tabccordion-list {
		@apply tw-flex tw-flex-row tw-gap-2;

		.Tabccordion-listItem {
			cursor: pointer;
			flex-grow: 1;
			flex-basis: 0;

			a {
				color: theme('colors.gray.DEFAULT');
				display: inline-block;
				text-decoration: none;
			}

			button, a {
				@apply tw-text-xl tw-font-light tw-justify-between tw-py-2 tw-w-full tw-text-center;
				border-bottom: 1px solid theme('colors.gray.400');
				height: 100%;

				:first-child {
					margin-bottom: 1px;
				}

				&.isActive {
					@apply tw-font-normal;
					border-bottom: 2px solid theme('colors.gray.DEFAULT') !important;
					:first-child {
						margin-bottom: 0;
					}
					&:hover {
						border-bottom: 2px solid theme('colors.gray.DEFAULT') !important;
					}
				}

				&:hover {
					@apply tw-text-gray;
					--text-opacity: 1;
					color: rgba(51,51,51,var(--text-opacity));
					outline: 1px dotted #757575!important;
				}
				&:focus-within {
					color: rgba(51,51,51,var(--text-opacity));
					box-shadow: none!important;
					outline: 1px dotted #757575;
				}
			}

		}
	}

	/* This is the tab/accordion content*/
	.Tabccordion-contentContainer {
		border-bottom: 0;
		clear: left;

		.Tabccordion-accordion {
			width: 100%;
			padding: 10px;
			display: none;

			&.isActive {
				display: none;
			}
		}

		.Tabccordion-content {
			max-height: 0;
			overflow: hidden;
			display: none;
		}

		.isActive {
			max-height: unset;
			overflow: unset;
			display: block;
		}

		.content-max-height {
			max-height: 2000px;
		}
	}
}

/* On small screens switch to the accordion styles, except if you are tabs only .Tabccordion--tabs */
@media (max-width: 45em) {
	.Tabccordion-wrapper {
		&.no-border {
			.Tabccordion-contentContainer.isLast {
				@apply tw-border-0 #{!important};
			}
		}
		.Tabccordion-contentContainer.isLast {
			@apply tw-border-gray tw-block;
			border-bottom: 1px solid !important;
		}
		&:not(.Tabccordion--tabs) {
			@include tabccordion-accordion;
		}

		&.Tabccordion--accordion {
			.Tabccordion-content {
				padding-top: 0px;
			}
		}
	}
}

/*-----------------------------------------------------------
	Tabccordion accordion only
-----------------------------------------------------------*/

.Tabccordion-wrapper.Tabccordion--accordion {
	@include tabccordion-accordion;
	.Tabccordion-contentContainer.isLast {
		@apply tw-border-gray tw-block;
		border-bottom: 1px solid !important;
	}
}

/*-----------------------------------------------------------
	Tabccordion details
-----------------------------------------------------------*/

.Tabccordion-wrapper.Tabccordion--accordion.Tabccordion--details {
	.Tabccordion-tabTitle {
		@apply tw-font-bold;
		font-size: 1.0625rem;
	}
	.Tabccordion-contentContainer {
		@apply tw-border-0 #{'!important'};
		.content-max-height {
			@apply tw-max-h-56;
		}
		.Tabccordion-accordion {
			@apply tw-flex tw-flex-row-reverse tw-justify-end tw-border-0 tw-gap-2 tw-px-2 tw-py-2 tw-min-h-0;
			@apply tw-border-0 tw-space-x-0 #{'!important'};
			.Tabccordion-status {
				@include triangle(6px, theme('colors.gray.DEFAULT'), right);
				@apply tw-transition-transform tw-duration-200;
				&::before,
				&::after {
					@apply tw-hidden;
				}
			}
			&.isActive {
				.Tabccordion-status {
					@apply tw-rotate-90;
				}
			}
		}
		.Tabccordion-content {
			@apply tw-px-1 tw-pt-4 tw-pb-4 tw-overflow-visible;
		}
	}
}

/*-----------------------------------------------------------
	Tabccordion details large
-----------------------------------------------------------*/

.Tabccordion-wrapper.Tabccordion--accordion.Tabccordion--detailsLarge {
	.Tabccordion-tabTitle {
		@apply tw-text-xl tw-pl-1;
	}
	.Tabccordion-contentContainer {
		@apply tw-border-b-0 #{'!important'};
		.content-max-height {
			@apply tw-max-h-56;
		}
		.Tabccordion-accordion {
			@apply tw-flex tw-flex-row-reverse tw-justify-end tw-border-0 tw-gap-2 tw-px-0 tw-py-5 tw-min-h-0;
			@apply tw-border-0 tw-space-x-0 #{'!important'};
			@apply tw-font-normal;
			.Tabccordion-status {
				@include triangle(6px, theme('colors.gray.DEFAULT'), right);
				@apply tw-transition-transform tw-duration-200;
				&::before,
				&::after {
					@apply tw-hidden;
				}
			}
			&.isActive {
				.Tabccordion-status {
					@apply tw-rotate-90;
				}
			}
		}
		.Tabccordion-content {
			@apply tw-px-0 tw-pb-8 tw-overflow-visible;
		}
	}
}

/*-----------------------------------------------------------
	Tabccordion orderSummary
-----------------------------------------------------------*/

.Tabccordion-wrapper.Tabccordion--accordion.Tabccordion--orderSummary {
	.Tabccordion-contentContainer,
	.Tabccordion-accordion {
		@apply tw-border-gray-400 #{'!important'};
	}
	.Tabccordion-accordion {
		@apply tw-px-0 tw-py-4 tw-min-h-0;
	}
	.Tabccordion-contentContainer .Tabccordion-content {
		@apply tw-pt-0 tw-pb-6 tw-overflow-visible;
	}
	#order-summary-group0-tabccordion-contentContainer-order-summary-charges {
		.Tabccordion-content {
			@apply tw-py-3;
		}
		.Tabccordion-accordion {
			@apply tw-border-0 tw-border-b tw-border-solid #{'!important'};
			.Tabccordion-status {
				@apply tw-hidden;
			}
			&.isDisabled {
				@apply tw-text-gray;
			}
			&.isDisabled,
			&.isDisabled > * {
				@apply tw-opacity-100;
			}
		}
		.Tabccordion-tabTitle {
			@apply tw-text-xl;
		}
	}
}

/*-----------------------------------------------------------
	Tabccordion overrides
-----------------------------------------------------------*/

.Tabccordion-wrapper.faq.Tabccordion--accordion {
	> div {
		@apply tw-mb-6;
	}
	.row .column {
		@apply tw-p-0;
	}
	.Card-heading {
		padding-top: 45px;
		margin-top: -45px;
	}
	.Tabccordion-contentContainer {
		@apply tw-mb-0;
		.Tabccordion-accordion {
			@apply tw-border-0 tw-bg-transparent tw-p-0 tw-pt-2 tw-pb-2;
			background: none;
		}
		.Tabccordion-status {
			@apply tw-hidden;
		}
		.Tabccordion-content {
			@apply tw-border-0 tw-p-0;
		}
	}
}
@screen md {
	.Tabccordion-wrapper {
		&.faq.Tabccordion--accordion {
			.Card-heading {
				padding-top: 0;
				margin-top: 0;
			}
		}

		.Tabccordion-list {
			.Tabccordion-listItem {
				&.Tabccordion-listItem--minimal {
					&.no-border {
						@apply tw-mr-5;

						&:last-child {
							@apply tw-mr-0;
						}
						button, a {
							&.isActive {
								border: none;
							}
						}
					}
				}
			}
		}
	}
}
