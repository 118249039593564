.MagicModalDropShadow {
	box-shadow: 0 3px 6px theme('colors.gray.DEFAULT');
}
.MagicModal {
	$closeBtnSize: 32px;

	@apply tw-bg-white tw-relative tw-mx-auto;
	max-width: calc(100vw - 20px);
	width: calc(100vw - 20px);
	padding: 30px 15px;
	z-index: 500;
	.MagicModal-header {
		@apply tw-flex tw-justify-end;
		margin: 0 0 25px;
	}
	.MagicModal-title {
		@apply tw-relative tw-grow;
		top: -5px;
	}
	.MagicModal-closeBtnWrapper {
		@apply tw-relative tw-shrink-0;
		height: 22px;
		width: 22px;
		margin: 0 0 0 $closeBtnSize;
	}
	.MagicModal-closeBtn {
		@apply tw-z-10;
	}
	.MagicModal-closeBtn,
	.MagicModal-reverseCloseBtn {
		@apply tw-absolute tw-top-0 tw-right-0 tw-w-full tw-h-full tw-cursor-pointer;

		&::before,
		&::after {
			@apply tw-bg-gray-300 tw-absolute tw-top-0 tw-left-0;
			content: '';
		}

		&::before {
			height: $closeBtnSize;
			transform: rotate(45deg) translate(4px, -11px);
			width: 1px;
		}

		&::after {
			height: 1px;
			transform: rotate(45deg) translate(4px, 11px);
			width: $closeBtnSize;
		}
		// For specificity
		&.MagicModal-closeBtn:focus,
		&.MagicModal-reverseCloseBtn:focus,
		&.MagicModal-closeBtn:hover,
		&.MagicModal-reverseCloseBtn:hover {
			@apply tw-shadow-none;
			outline: 1px dotted theme('colors.gray.300');
			outline-offset: 8px;
		}
	}
	.MagicModal-reverseCloseBtn {
		&::before,
		&::after {
			@apply tw-bg-white;
		}
	}
	&.MagicModal--fullBleed {
		@apply tw-p-0;
		.MagicModal-header {
			@apply tw-absolute tw-w-full;
		}
		.MagicModal-closeBtnWrapper {
			top: 30px;
			right: 25px;
		}
	}
}

@screen md {
	.MagicModal {
		max-width: 900px;
		padding: 30px 25px;
		&.MagicModal--fullBleed {
			@apply tw-p-0;
		}
	}
}
