// BEGIN Tailwind custom base styles

// GENERAL

// *,
// *::before,
// *::after {
// 	box-sizing: border-box;
// 	border-width: 0;
// 	border-style: none;
// 	border-color: unset;
// }
body {
	@apply tw-text-gray tw-leading-normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
strong {
	@apply tw-font-semibold;
}
img {
	@apply tw-inline-block tw-align-middle;
	-ms-interpolation-mode: bicubic;
}
button {
	line-height: inherit;
}
ul,
ol,
dl {
	@apply tw-mb-4 tw-list-outside tw-leading-normal;
}
a {
	@apply tw-underline tw-text-blue;
	&:hover {
		@apply tw-text-gray;
	}
}
p {
	@apply tw-mb-4 tw-leading-normal;
	font-size: inherit;
}

// FORMS

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}
select {
	// Having right padding messes up IE.
	@apply tw-rounded-none tw-w-full tw-bg-white tw-pr-0;
	padding-right: 0;
	height: 27px;
}
input,
select,
textarea {
	@apply tw-text-gray;
	border: 1px solid theme('colors.gray.400');
	&:focus {
		box-shadow: 0 0 8px theme('colors.gray.400');
		outline: none;
	}
	max-width: 100%;
	padding: 0 5px;
	margin: 0;
	box-shadow: none;
	&[disabled] {
		opacity: 0.5;
	}
}
input {
	&[type='radio'],
	&[type='checkbox'] {
		&:focus {
			box-shadow: none;
			outline: 1px dotted theme('colors.gray.300');
			outline-offset: 2px;
		}
	}
}
textarea {
	resize: none;
	padding: 5px 10px;
	line-height: 1.5;
}
::-webkit-input-placeholder {
	line-height: normal;
}
// Text-like inputs
input:not([type='button']):not([type='checkbox']):not([type='color']):not([type='file']):not([type='image']):not([type='radio']):not([type='range']):not([type='reset']):not([type='submit']) {
	appearance: none;
	border-radius: 0;
	height: 27px;
}
label,
legend {
	font-weight: bold;
	display: block;
	padding: 0 0 5px;
	cursor: pointer;
}
// Remove controls from Safari and Chrome
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	appearance: none;
	// Removes leftover margin
	margin: 0;
}
// Remove controls from Firefox
input[type='number'] {
	appearance: textfield;
}

// FOCUS

button:not(.selector-value):not([class*='Button']),
a:not(.selector-value):not([class*='Button']) {
	&:focus {
		outline: 1px dotted theme('colors.gray.300');
		outline-offset: 2px;
		box-shadow: 0px 0px 1px theme('colors.gray.400');
	}
}

// END Tailwind custom base styles