

@media all {

	.SubMenu .SubMenu-item.SubMenu-item--group {
		.SubMenu-item {
			a:link,
			a:visited {
				padding: 0 10px;
			}

			&:first-child {
				a:link,
				a:visited {
					padding-left: 0;
				}
			}
		}
	}
}
