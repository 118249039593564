

@media all {

	.Divider {
		> * {
			border-style: solid;
			border-color: theme('colors.gray.400');
			border-width: 0;
		}
	}
}
