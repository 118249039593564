@import 'css/lib/tailwind-custom/vars';

@import 'tailwindcss/base';
@import 'css/lib/tailwind-custom/base';

@import 'tailwindcss/components';
@import 'css/lib/tailwind-custom/components';

@import 'tailwindcss/utilities';
@import 'css/lib/tailwind-custom/utilities';

// Debugger styles
//$foobar: theme('colors.gold.DEFAULT') !important;
//
//body {
//	appearance: none;
//	&::before {
//		@apply tw-bg-red tw-px-2 tw-absolute tw-left-0;
//		content: 'Tailwind activated';
//		top: 38px;
//		z-index: 9999;
//		color: $foobar;
//	}
//}

@layer utilities {
	.tw-testing {
		display: inline-block;
	}
}
