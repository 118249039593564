@import 'css/lib/tailwind-custom/triangle';

@media screen {
	.top-menu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			position: relative;
		}
		> ul {
			@apply tw-flex tw-relative;
			width: 100%;
			display: flex;
			justify-content: space-between;

			@screen lg {
				justify-content: center;
				> li:not(:last-child) {
					margin-right: min(50px, 2.5vw);
				}
			}

			> li {
				display: inline-block;
				line-height: 15px;
				padding: 15px 0;

				> .subMenu {
					left: 0 !important;
				}

				&:first-child > a::after {
					left: 0;
					width: calc(100% + 1em);
				}

				&:last-child > a::after {
					right: 0;
					width: calc(100% + 1em);
				}

				> a {
					@apply tw-inline tw-leading-4 tw-relative tw-border-none;

					&:focus ~ button::after {
						width: 100%;
					}

					&:focus ~ button::before {
						opacity: 1;
					}

					> span {
						@apply tw-font-bold tw-text-gray tw-inline-block;
						font-size: 14px;
						white-space: nowrap;

						@screen bsLgOnly {
							font-size: 16px;
						}

						@screen lg {
							font-size: 17px;
						}
					}
				}

				.openMenuHolder {
					position: relative;
					overflow: visible;

					& > button {
						background-color: transparent;
						border: none;
						display: block;
						height: 1px;
						left: 0;
						bottom: -18px;
						padding: 0;
						position: absolute;
						text-indent: -99999px;
						width: 100%;

						&:focus {
							outline: none !important;

							&::after {
								@apply tw-w-full;
								outline: 1px solid theme('colors.blue.DEFAULT');
								outline-offset: 6px;
							}

							&::before {
								opacity: 1;
							}
						}

						&[aria-expanded='true'] {
							&::after {
								@apply tw-w-full;
							}

							&::before {
								opacity: 1;
							}
						}

						&::before {
							@apply tw-transition-opacity tw-duration-200 tw-ease-in-out tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
							content: '';
							@include triangle(8px, theme('colors.gray.DEFAULT'), down);
							z-index: 152;
							visibility: hidden;
						}

						&::after {
							@apply tw-bottom-0 tw-m-0 tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
							border-top: 3px solid theme('colors.gray.DEFAULT');
							transition: width 200ms ease-in-out;
							content: '';
							z-index: 151;
						}
					}
				}


				&:hover .openMenuHolder > button::after {
					// IE
					width: 100%;
				}
				&:focus-within .openMenuHolder > button::after {
					width: 100%;
					visibility: visible;
				}

				&:focus-within .openMenuHolder > button::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.subMenu {
			top: 100%;
			box-shadow: 0px 3px 6px #0000004d;
		}
		&.menu-visible {
			position:relative;
			&:after {
				content: "";
				position: absolute;
				width: 100vw;
				top: 46px;
				height: calc(100vh + 1000px);
				left: 50%;
				transition-delay: 1s;
				transition: visibility 1s linear 1s;
				transform: translate(-50%, 0);
				z-index: 140;
				pointer-events: none;
				background: #333;
				background: linear-gradient(180deg, rgba(51, 51, 51, 0.7) 0%, rgba(51, 51, 51, 0.6) 50%, rgba(51, 51, 51, 0) 100%);
			}
		}
	}

	.top-menu > ul > li.showMenu .subMenu {
		@apply tw-block;
	}

	@supports (display: grid) {
		.top-menu {
			> ul {
				width: 100%;
				margin: 0 auto;
				display: grid;
				grid-template-columns: repeat(13, auto);
			}
		}
	}
}
