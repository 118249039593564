.order-confirmation {
	.mobileNumber-field-group {
		@apply tw-flex;
		width: calc(100% - theme('width[8]'));
	}

	.address-primary-email {
		max-width: 220px;
	}
}

@mixin medium-styles() {
	.order-confirmation {
		.mobileNumber-field-group {
			@apply tw-flex tw-w-auto;
		}
	}
}

@screen md {
	@include medium-styles();
}

@screen print {
	@include medium-styles();

	#react-magic-modal {
		display: none;
	}
}
