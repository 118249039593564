
.Card.Card--imgTextOverlay {
	@apply tw-relative tw-flex;
	&.Card--imgTextOverlay__whiteBkg {
		.Card-content {
			background-color: rgba(255, 255, 255, .9);
			@apply tw-mt-3;
		}
	}
	// Stack on mobile
	&.mobileStack {
		@apply tw-flex-col;
		.Card-content {
			@apply tw-order-2 tw-relative tw-p-0;
		}
		.Card-imgBkg {
			@apply tw-order-1 tw-mb-1;
		}
	}
	.Card-content {
		@apply tw-pt-2 tw-pl-3 tw-pr-3 tw-pb-3 tw-absolute tw-z-0;
		.Card-title {
			@apply tw-mb-4;
		}
	}
	.Card-imgBkg {
		@apply tw-object-fill;
	}
}




@screen md {
	.Card.Card--imgTextOverlay {
		@apply tw-relative;
		&.Card--imgTextOverlay__whiteBkg {
			.Card-content {
				@apply tw-ml-3 tw-pt-2 tw-pl-3 tw-pr-3 tw-pb-3;
			}
		}
		// Stack on mobile
		&.mobileStack {
			@apply tw-relative tw-flex;
			.Card-content {
				@apply tw-pt-3 tw-pl-5 tw-pr-5 tw-pb-3 tw-absolute tw-z-0;
			}
			.Card-imgBkg {
				@apply tw-order-1 tw-mb-4;
			}
		}
		.Card-content {
			@apply tw-pt-4 tw-pl-6 tw-pr-6;
			@apply tw-absolute;
		}
		.Card-imgBkg {
			@apply tw-object-fill;
		}
	}
}
