@media all {
	.ButtonGroup {
		@apply tw-inline-flex;
		> * + * {
			margin-left: 25px;
		}
		&.ButtonGroup--stacked {
			@apply tw-flex tw-flex-col tw-items-start;
			> * + * {
				margin-left: 0;
				margin-top: 15px;
			}
		}
		&.ButtonGroup--stackedSmallOnly {
			@apply tw-flex tw-flex-col tw-items-start;
			> * + * {
				margin-left: 0;
				margin-top: 25px;
			}
		}
		&.ButtonGroup--stackedCentered {
			@apply tw-inline-flex tw-flex-col tw-items-center;
			> * + * {
				margin-left: 0;
				margin-top: 25px;
			}
		}
		&.ButtonGroup--pipeDelimited {
			> * + * {
				margin: 0;
				&::before {
					border-left: 1px solid theme('colors.gray.DEFAULT');
					margin: 0 0.05em 0 0.7em;
					padding: 0 0.05em 0 0.7em;
					width: 1px;
					height: 0.8em;
					content: " ";
				}
			}
		}
	}
}
@screen md {
	.ButtonGroup {
		&.ButtonGroup--stackedMediumUp {
			@apply tw-flex tw-flex-col tw-items-start;
			> * + * {
				margin-left: 0;
				margin-top: 25px;
			}
		}

		&.ButtonGroup--stackedSmallOnly {
			@apply tw-flex-row;
			> * + * {
				margin-left: 25px;
				margin-top: 0px;
			}
		}
	}
}
