.subMenuPromos {
	@apply tw-bg-gray-500 tw-grid;
	padding: 26px 26px 0px 26px;
    gap: 21px 26px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: fit-content(217px) fit-content(217px);
    min-width: 35%;
    max-width: 553px;
    flex: 0 0 35%;

    img {
        @apply tw-object-cover;
        height: 168px;
    }

	@screen smBsOnly {
		@apply tw-hidden;
	}
    &.subMenuPromos_grid_4pack {
        width: 52%;
        max-width: 817px;
        flex: 0 0 52%;
        :nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 2;
        }
        :nth-child(3) {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }
    &.subMenuPromos_grid_1over2 {
        :first-child {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    &.subMenuPromos_grid_2over1 {
        :last-child {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    &.subMenuPromos_grid_1and2 {
        width: 52%;
        max-width: 817px;
        flex: 0 0 52%;
        &.subMenuPromos_grid_1and2_xl {
            width: 69%;
            max-width: 1081px;
            flex: 0 0 69%;
        }
        :first-child {
            grid-row-start: 1;
            grid-row-end: 3;
            img {
                height: 386px;
            }
        }
    }
    &.subMenuPromos_grid_2and1 {
        width: 52%;
        max-width: 817px;
        flex: 0 0 52%;
        :nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        :last-child {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            img {
                height: 386px;
            }
        }
    }
    &.subMenuPromos_grid_1and1 {
        grid-template-rows: fit-content(415px);
    }
    &.subMenuPromos_grid_1over1 {
        grid-template-columns: 1fr;
    }
    a {
        @apply tw-no-underline tw-block;

        .subMenuPromos_title {
            @apply tw-block tw-mt-2 tw-font-semibold tw-text-gray tw-leading-snug;
        }
        &:not(.selector-value):not([class*=Button]):hover,
        &:not(.selector-value):not([class*=Button]):focus {
            @apply tw-underline;
            outline: 1px dotted theme('colors.gray.300');
		    outline-offset: 2px;
		    box-shadow: 0px 0px 1px theme('colors.gray.400');

            .subMenuPromos_title {
            @apply tw-text-blue;
            }
        }
    }
}