

@media all {

  .Card.Card--list {
	  margin: 0 0 40px;
	  position: relative;
	  width: 467px;

	  &:not(.column) {
		  padding: 0;
	  }

	  &.Card--list__autoWidth {
		width: auto;
	  }
	  /*to give the content below the image some top padding*/
	  div.row:nth-child(2){
		  padding-top: 10px;
	  }

	  > .Card-contentContainer {
		  margin: 0;
		  position: relative;
	  }
	  .Card-content {
		  padding: 0 5px;
		  position: absolute;
		  bottom: 0;
		  left: 0;
	  }
	  .Card-fullImg {
		  align-self: flex-end;
		  max-width: none;
	  }
	  ul, .Card-linkList {
		  list-style: none;
		  padding: 0;
		  margin-top: 10px;
		  margin-left: 0px;
		  > * {
			  margin: 0 0 12px;
		  }
		  .Card-linkListContent {
			  margin: 5px 0 20px;
			  ul {
				  list-style: square;
				  padding: 5px 0 5px 25px;
				  &.noBullets {
					  list-style: none;
					  padding: 5px 0 5px 0;
					  li {
						  padding: 0;
						  line-height: 1.3;
					  }
				  }
			  }
		  }
		  &.Card-linkList--indented {
			  padding-left: 10px;
		  }
	  }
	  .Card-heading {
		  margin-top: 20px;
	  }

	&.Card--list__noOverlay {
	  .Card-content {
		position: relative;
	  }
	  .Card-linkList {
		margin-top: 0;
	  }
	}
	&.Card--list__bullet {
		ul {
			list-style: disc;
			margin-left: 22px;
		}
	}
  }

}
