@mixin button-base {
	@apply tw-appearance-none tw-relative tw-align-middle tw-rounded-none tw-cursor-pointer tw-no-underline tw-font-bold tw-inline-flex;
	@apply tw-flex-row tw-justify-center tw-items-center tw-select-none tw-leading-none;
	$anim-speed: 200ms;
	$anim-timing-fn: ease-out;

	[data-whatinput='mouse'] & {
		outline: 0;
	}
	padding: 5px 15px;
	background: theme('colors.gray.DEFAULT');
	border: 1px solid transparent;
	font-size: 18px;
	&,
	&:link,
	&:visited,
	&:active {
		@apply tw-text-white;
	}
	&:focus, &.focus,
	&:hover, &.hover {
		@apply tw-text-gray tw-outline-none;
		background: theme('colors.white.DEFAULT');
		border-color: theme('colors.gray.DEFAULT');
	}
	&:focus:not(.is-animating) {
		box-shadow: 0 0 4px theme('colors.gray.400');
		&.is-disabled {
			box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
		}
	}
	&[disabled]:not(.is-animating),
	&.is-disabled:not(.is-animating) {
		@apply tw-text-white;
		background: theme('colors.gray.400');
		&:hover,
		&:focus {
			border-color: transparent;
		}
	}
	&.no-print-underline {
		text-decoration: none !important;
	}
	// Adding states and animations. Used for Add to Cart buttons.
	&.js-button-flasher {
		&::before {
			// IE needs top and left resets.
			@apply tw-text-gray tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-opacity-0 tw-whitespace-nowrap tw-flex tw-flex-row;
			@apply tw-justify-center tw-items-center;
			background: theme('colors.white.DEFAULT');
		}
		&.is-animating-in {
			&,
			&:link,
			&:visited,
			&:active,
			&:hover,
			&:focus {
				border-color: theme('colors.gray.DEFAULT');
			}
			&::before {
				@apply tw-opacity-100;
				animation: Button-fade-in $anim-speed $anim-timing-fn;
			}
		}
		&.is-animating-out::before {
			@apply tw-opacity-0;
			content: '';
			animation: Button-fade-out $anim-speed $anim-timing-fn;
		}
		&.is-pending {
			&::before {
				content: 'Adding Item';
			}
			&[data-is-plural='true']::before {
				content: 'Adding Items';
			}
		}
		&.is-resolved {
			&::before {
				content: 'Added Item';
			}
			&[data-is-plural='true']::before {
				content: 'Added Items';
			}
		}
	}
}
