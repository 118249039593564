@mixin Heading {
	@apply tw-leading-snug tw-text-brown tw-m-0 tw-p-0 tw-font-normal;
}

@mixin Heading--1 {
	@apply tw-font-extralight tw-leading-tight;
	font-size: 42px;
	@screen md {
		@apply tw-text-5xl;
	}
}

@mixin Heading--2 {
	@apply tw-font-extralight tw-text-navy tw-leading-tight;
	font-size: 36px;
	@screen md {
		@apply tw-text-4xl;
	}
}

@mixin Heading--3 {
	@apply tw-font-light tw-text-2xl tw-m-0;
	@screen md {
		margin: initial;
	}
}

@mixin Heading--4 {
	@apply tw-font-light tw-text-xl tw-leading-normal;
}

@mixin Heading--5 {
	@apply tw-font-light tw-text-lg tw-leading-normal;
}

@mixin Heading--6 {
	@apply tw-font-semibold tw-text-base;
}