#chat-widget-container {
	@apply tw-pointer-events-none md:tw-pointer-events-auto;
}

#chat-widget-minimized {
	@apply #{'sm:!tw-hidden'};

	&:not(.md-not-hidden) {
		@apply #{'md:!tw-hidden'};
	}
}
