#react-magic-modal {
	.fade-enter {
		opacity: 0.01;
		&.fade-enter-active {
			opacity: 1;
			transition: opacity 200ms ease-in;
		}
	}
	.fade-exit {
		opacity: 1;
		&.fade-exit-active {
			opacity: 0.01;
			transition: opacity 200ms ease-out;
		}
	}
	.react-magic-modal-open {
		@apply tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-start;
		z-index: 3000;
		.magic-modal-overlay {
			@apply tw-fixed tw-inset-0;
			z-index: 50;
			background: rgba(0, 0, 0, .5);
		}
	}
}
