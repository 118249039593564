.Card.Card--media {
	@apply tw-mb-5 tw-pb-8;
	.Card-content {
		@apply tw-pb-3;
	}
	.Card-img {
		@apply tw-mb-3;
	}
	&.Card--media__border-b {
		@apply tw-border-solid tw-border-b tw-border-gray-400;
	}
}

@screen md {
	.Card.Card--media {
		@apply tw-pb-0;
		.Card-img {
			@apply tw-mb-5;
		}
	}
}