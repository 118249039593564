.Card.Card--snippits {
	@apply tw-flex tw-flex-col tw-border-t tw-border-solid tw-border-gray-400;
	div {
		@apply tw-pt-6 tw-pb-6 tw-border-b tw-border-solid tw-border-gray-400;
	}
	div:last-of-type {
		@apply tw-pb-10 tw-border-b-0;
	}
	.Heading {
		@apply tw-mb-2;
	}
	p {
		@apply tw-text-gray tw-mb-1;
	}
}

@screen md {
	.Card.Card--snippits {
		@apply tw-flex tw-flex-row;
		div {
			@apply tw-pr-4 tw-pl-4 tw-pt-10 tw-pb-16 tw-border-0;
		}
	}
}