@import 'css/lib/tailwind-custom/triangle';

@media screen {
	.subMenu {
		top: 34px;
		text-align: left;
		z-index: 157;

		ul {
			list-style-type: none;
			margin: 0;

			li {
				margin-top: 10px;

				a,
				button {
					@apply tw-text-gray tw-block tw-text-sm tw-no-underline;
					&:hover,
					&:focus {
						@apply tw-text-blue tw-underline;
					}
				}
			}
		}
	}
	.top-links {
		@apply tw-flex tw-items-start;
	}
	.account-menu {
		position: relative;

		&.has-submenu {
			&:hover,
			&.has-focus {
				.top-link::after {
					width: 100%;
				}
				.top-link::before {
					opacity: 1;
				}
				.hover-fill {
					fill: theme('colors.gray.DEFAULT');
				}
				.subMenu {
					visibility: visible;
				}
			}
		}

		.sign-in {
			left: -160px;
			width: 275px;
			padding: 15px 10px;

			.login-container {
				width: 100%;
				margin: 0;
				padding: 0 0 20px 0;
				border-bottom: 3px solid theme('colors.white.DEFAULT');

				.sign-in-error {
					width: 100%;
				}

				.username-control-login-header,
				.password-control-login-header {
					width: 215px;
				}

				.rememberMe-field-group {
					display: none;
				}
			}

			h6 {
				margin: 0;
			}
		}

		.account-menu-list {
			left: -90px;
			width: 200px;
			padding: 0 10px 10px 10px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		}
	}

	.favorites-menu {
		position: relative;

		&.has-submenu {
			&:hover,
			&.has-focus {
				.top-link::after {
					width: 100%;
				}
				.top-link::before {
					opacity: 1;
				}
				.hover-fill {
					fill: theme('colors.gray.DEFAULT');
				}
				.subMenu {
					visibility: visible;
				}
			}
		}

		.favorites-menu-list {
			left: -90px;
			width: 200px;
			padding: 0 10px 10px 10px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		}
	}

	.top-link {
		@apply tw-self-start tw-block tw-relative tw-text-center tw-cursor-pointer;
		margin: 0 0 0 20px;
		width: 34px;
		height: 27px;

		&:hover,
		&:focus {
			&::after {
				width: 100%;
			}
			&::before {
				opacity: 1;
			}
			.hover-fill {
				fill: theme('colors.gray.DEFAULT');
			}
		}

		&::after {
			@apply tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
			content: '';
			transition: width 0.2s ease-in-out;
			border-top: 3px solid theme('colors.gray.DEFAULT');
			bottom: -8px;
			z-index: 161;
		}

		svg {
			width: 100%;

			.hover-fill {
				fill: theme('colors.white.DEFAULT');
			}
		}

		&.has-menu {
			&::before {
				@apply tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
				content: '';
				@include triangle(8px, theme('colors.gray.DEFAULT'), down);
				transition: opacity 0.2s ease-in-out;
				bottom: -16px;
				z-index: 161;
			}
		}
	}
	.header {
		#logo {
			margin-bottom: 20px;
			@screen bs {
				margin-bottom: 30px;
			}
			a {
				display: inline-block;
				img {
					width: 300px;
					@screen bsLgOnly {
						width: 320px;
					}
					@screen lg {
						width: 360px;
					}
				}
			}

			.here-to-help {
				display: none;
			}
		}

		#h_search {
			form {
				position: relative;
			}

			label {
				@apply tw-inline tw-text-gray tw-text-sm;
				margin-right: 5px;
			}

			.header-search-input, .header-search-input-placeholder {
				width: 175px;
				border: none;
				border-bottom: 1px solid theme('colors.gray.DEFAULT');
				box-shadow: none;
				@screen smBsOnly {
					width: 150px;
				}
			}

			.header-search-input-placeholder {
				@apply tw-inline-block tw-relative;
				bottom: -8.5px;
			}

			#h_searchButton {
				line-height: 12px;
				background: none;
				border: none;
				padding: 0;
				cursor: pointer;

				img {
					height: 20px;
				}
			}

			span {
				position: absolute;
				left: -99999px;
			}

			// Override rfk styles
			#rfk_search_container {
				left: 55px !important;
				top: 100% !important;

				&.rfk-sb .rfk_product .rfkx_ratingw .rfkx_rating {
					background-size: 100% 100%;
				}
			}
		}
	}
}
