@media screen {
	.breadcrumbs {
		@apply tw-text-gray tw-flex tw-p-0;
		margin: 4px 0;
		list-style: none;
		//margin: 0;
		span {
			@apply tw-text-xs;
		}
		a {
			@apply tw-text-gray tw-no-underline;
			&:hover {
				@apply tw-underline;
			}
		}
		.active {
			@apply tw-text-orange;
		}
		&.clearance .active {
			@apply tw-text-red;
		}
	}
	.breadcrumb {
		margin: 0 0.3em 0 0;
		.breadcrumb-spacer,
		a,
		span {
			@apply tw-text-sm;
		}
		.active {
			@apply tw-font-semibold tw-text-gray;
		}
	}
	.breadcrumb-spacer {
		@apply tw-inline-block;
		margin-left: 0.3em;
	}
}

@screen smOnly {
	.breadcrumbs {
		@apply tw-block;
		margin-top: 10px;
	}
	.breadcrumb {
		@apply tw-inline;
		.breadcrumb-spacer,
		a,
		span {
			@apply tw-text-xs;
		}
	}
}
