

@media all {

	.fixed-scrolling.fixed {
		.top {
			position: fixed;
			top: 0px;
		}

	}
}

