.Card.Card-footnote {
	@apply tw-text-2xs tw-text-gray-300 tw-relative;
	.Card-footnote--overlay {
		@apply tw-absolute;
		bottom: 8px;
		left: 16px;
	}
	.Card-footnote--below {
		@apply tw-static tw-mt-2
	}
}