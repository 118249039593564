
.MagicModal.fds {
	@apply tw-ml-0 tw-mr-0 tw-p-0;
	.MagicModal-header {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	.MagicModal-content {
		.fds-copy {
			padding: 15px 15px 30px;
		}
		.fds-headline {
			margin-bottom: 5px;
		}
	}
	.MagicModal-closeBtnWrapper {
		@apply tw-bg-white;
		padding: 16px;
		.MagicModal-closeBtn::before, .MagicModal-closeBtn::after {
			top: 5px;
			left: 5px;
		}
	}
}

.MagicModal.storage-giveaway {
	@apply tw-ml-0 tw-mr-0 tw-p-0;
	.MagicModal-header {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	.MagicModal-closeBtnWrapper {
		@apply tw-bg-white;
		padding: 16px;
		.MagicModal-closeBtn::before {
			@apply tw-bg-gray;
			top: 5px;
			left: 5px;
			width: 2px;
		}
		.MagicModal-closeBtn::after {
			@apply tw-bg-gray;
			top: 5px;
			left: 5px;
			height: 2px;
		}
	}
}