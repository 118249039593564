// BEGIN Tailwind custom utility styles

// .tw-user-drag-none {
// 	-webkit-user-drag: none;
// 	-moz-user-drag: none;
// 	-o-user-drag: none;
// 	 user-drag: none;
// }

// .tw-user-select-none {
// 	-webkit-touch-callout: none;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	 user-select: none;
// }

@screen screen {
	.tw-hidden-for-screen {
		@apply tw-hidden #{'!important'};
	}
}
@screen print {
	// specificty yo
	html body .tw-hidden-for-print {
		@apply tw-hidden #{'!important'};
	}
}

// END Tailwind custom utility styles
