#react-magic-modal .engage-blackout-modal {
	.modal-caption {
		@apply tw-text-white tw-text-center tw-bg-gray tw-fixed tw-w-full tw-top-0 tw-left-0 tw-flex-col tw-items-center tw-font-semibold tw-uppercase;
		z-index: 505;
	}
	&.react-magic-modal-open {
		.magic-modal-overlay {
			@apply tw-bg-gray-200;
		}
		.legacy-modal .modal-content {
			padding-top: 0;
		}
	}
}