@media all {
	.HollowButton {
		@include button-base;
		@apply tw-text-base;
		background: theme('colors.white.DEFAULT');
		border-color: theme('colors.navy.DEFAULT');
		&,
		&:link,
		&:visited,
		&:active {
			@apply tw-text-navy;
		}
		&:hover,
		&.hover,
		&.focus,
		&:focus:not(.is-animating):not(.is-disabled) {
			@apply tw-text-white;
			border-color: theme('colors.navy.DEFAULT');
			background: theme('colors.navy.DEFAULT');
			outline: none;
		}
		&.focus,
		&:focus:not(.is-animating):not(.is-disabled) {
			border-color: theme('colors.white.DEFAULT');
			box-shadow: 0 0 4px theme('colors.navy.DEFAULT');
		}
		&[disabled]:not(.is-animating),
		&.is-disabled:not(.is-animating) {
			&:hover {
				@apply tw-bg-gray-400;
			}
			border-color: transparent;
		}
		@each $name, $color in $button-palette2 {
			&.HollowButton--#{"" + $name} {
				border-color: $color;
				&,
				&:link,
				&:visited,
				&:active {
					color: $color;
				}
				&:hover,
				&.hover,
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					@apply tw-text-white;
					border-color: $color;
					background: $color;
				}
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					border-color: theme('colors.white.DEFAULT');
					box-shadow: 0 0 4px $color;;
				}
				&[disabled]:not(.is-animating),
				&.is-disabled:not(.is-animating) {
					border-color: transparent;
				}
			}
		}
		&.HollowButton--transparent:not(:hover) {
			background: transparent;
		}
		&.HollowButton--white:hover {
			@apply tw-text-white;
		}
		&.HollowButton--white.HollowButton--transparent:hover {
			@apply tw-text-gray;
		}

		&.HollowButton--white {
			&:focus:not(.is-animating):not(.is-disabled) {
				border-color: theme('colors.gray.DEFAULT') !important;
				color: theme('colors.gray.DEFAULT') !important;
			}
		}
	}
	.HollowButtonSupport {
		@include button-base;
		@apply tw-text-base tw-text-gray tw-font-normal;
		@screen md {
			@apply tw-text-sm;
		}
		padding: 5px;
		background: theme('colors.white.DEFAULT');
		border-color: theme('colors.gray.DEFAULT');
		&,
		&:link,
		&:visited,
		&:active {
			@apply tw-text-gray;
		}
		&.focus,
		&.hover,
		&:focus:not(.is-animating):not(.is-disabled),
		&:hover {
			@apply tw-text-white;
			background: theme('colors.gray.DEFAULT');
			border-color: theme('colors.gray.DEFAULT');
			outline: none;
		}
		&.focus,
		&:focus:not(.is-animating):not(.is-disabled) {
			border-color: theme('colors.white.DEFAULT');
			box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
		}
		&[disabled]:not(.is-animating),
		&.is-disabled:not(.is-animating) {
			border-color: transparent;
		}
		@each $name, $color in $button-palette2 {
			&.HollowButtonSupport--#{"" + $name} {
				border-color: $color;
				&,
				&:link,
				&:visited,
				&:active {
					color: $color;
				}
				&:hover,
				&.hover,
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					@apply tw-text-white;
					border-color: $color;
					background: $color;
				}
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					border-color: theme('colors.white.DEFAULT');
					box-shadow: 0 0 4px $color;
					&.is-disabled {
						box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
					}
				}
				&[disabled]:not(.is-animating),
				&.is-disabled:not(.is-animating) {
					border-color: transparent;
				}
			}
		}
	}
	// This is the same as Support but different padding.
	.HollowButtonTiny {
		@include button-base;
		@apply tw-text-base tw-font-normal;
		background: theme('colors.white.DEFAULT');
		border-color: theme('colors.gray.DEFAULT');
		&,
		&:link,
		&:visited,
		&:active {
			@apply tw-text-gray-100;
		}
		&:hover,
		&.hover,
		&.focus,
		&:focus:not(.is-animating):not(.is-disabled) {
			@apply tw-text-white;
			border-color: theme('colors.gray.DEFAULT');
			background: theme('colors.gray.DEFAULT');
			outline: none;
		}
		&.focus,
		&:focus:not(.is-animating):not(.is-disabled) {
			border-color: theme('colors.white.DEFAULT');
			box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
		}
		&[disabled]:not(.is-animating),
		&.is-disabled:not(.is-animating) {
			border-color: transparent;
		}
		@each $name, $color in $button-palette2 {
			&.HollowButtonTiny--#{"" + $name} {
				border-color: $color;
				&,
				&:link,
				&:visited,
				&:active {
					color: $color;
				}
				&:hover,
				&.hover,
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					@apply tw-text-white;
					border-color: $color;
					background: $color;
				}
				&.focus,
				&:focus:not(.is-animating):not(.is-disabled) {
					border-color: theme('colors.white.DEFAULT');
					box-shadow: 0 0 4px $color;
					&.is-disabled {
						box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
					}
				}
				&[disabled]:not(.is-animating),
				&.is-disabled:not(.is-animating) {
					border-color: transparent;
				}
			}
		}
	}

	@screen md {
		.HollowButtonTiny {
			@apply tw-text-sm;
		}
	}
}
