@media screen {

	.react-spinner-wrapper {
		@apply tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-h-full tw-w-full tw-z-10;
		opacity: 0.8;
		&.global-spinner {
			position: fixed;
			z-index: 50;
		}
	}

	.react-spinner {
		position: relative;
		width: 32px;
		height: 32px;
		top: 50%;
		left: 50%;
		&.react-spinner-align-top {
			top: 10%;
		}
	}

	.react-spinner_bar {
		@apply tw-bg-gray tw-absolute;
		animation: react-spinner_spin 1.2s linear infinite;
		border-radius: 5px;
		width: 20%;
		height: 7.8%;
		top: -3.9%;
		left: -10%;
	}

	@keyframes react-spinner_spin {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0.15;
		}
	}

	@-moz-keyframes react-spinner_spin {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0.15;
		}
	}

	@-webkit-keyframes react-spinner_spin {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0.15;
		}
	}

	/* Crossfade the sign in and forgotten password mode */
	/* testing css transitions*/
	.crossfade-loader-enter {
		opacity: 0.01;
		transition: opacity .4s ease-out;
	}

	.crossfade-loader-enter-active {
		opacity: .8;
	}

	.crossfade-loader-leave {
		opacity: .8;
		transition: opacity .4s ease-out;
	}

	.crossfade-loader-leave-active {
		opacity: 0.01;
	}
}
