// TODO(aboyer) Rename this once button-palette no longer exists.
$button-palette2: (
	blue: theme('colors.blue.DEFAULT'),
	brown: theme('colors.brown.DEFAULT'),
	gray: theme('colors.gray.DEFAULT'),
	orange: theme('colors.orange.DEFAULT'),
	navy: theme('colors.navy.DEFAULT'),
	red: theme('colors.red.DEFAULT'),
	white: theme('colors.white.DEFAULT'),
);

:export {
		blue: theme('colors.blue.DEFAULT');
		brown: theme('colors.brown.DEFAULT');
		gray: theme('colors.gray.DEFAULT');
		orange: theme('colors.orange.DEFAULT');
		navy: theme('colors.navy.DEFAULT');
		red: theme('colors.red.DEFAULT');
		white: theme('colors.white.DEFAULT');
}