@import 'css/lib/tailwind-custom/arrow';

@media all {
	.Pager {
		overflow: hidden;
		position: relative;
	}
	.Pager-inside {
		@apply tw-bg-white tw-relative;
		&.Pager-inside--magicRecos {
			min-height: 210px;
		}
	}
	.Pager-prevBtn,
	.Pager-nextBtn {
		@apply tw-absolute tw-z-10 tw-bg-white tw-cursor-pointer;
		top: calc(50% - 16px);
		font-size: 20px;
		width: 34px;
		height: 34px;
		border-radius: 34px;

		&::before {
			@apply tw-absolute;
			width: 14px;
			height: 14px;
			top: 9px;
		}
	}
	.Pager-prevBtn {
		position: absolute;
		left: 5px;

		&::before {
			@include arrow(16px, theme('colors.gray.DEFAULT'), left, 1px);
			position: absolute;
			left: 12px;
		}

	}
	.Pager-nextBtn {
		position: absolute;
		right: 5px;

		&::before {
			@include arrow(16px, theme('colors.gray.DEFAULT'), right, 1px);
			position: absolute;
			left: 5px;
		}
	}

	.Pager-inside {
		float: left;
		width: 100%;
		position: relative;
	}

	.Pager-nav {
		margin-top: 320px;
		padding-bottom: 4px;
		text-align: center;
	}

	.Pager-navLink {
		width: 24px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		display: inline-block;
		margin: 0 4px;

		&.selected {
			@apply tw-bg-blue tw-text-white;
		}
	}
}