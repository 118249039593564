.delivery-container {
	@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center;
	padding: 20px 0;

	.delivery-icon {
		flex: 0 0 auto;
		margin-right: 5px;
	}

	.delivery-content {
		flex: 1 1 auto;
	}

	.delivery-instructions {
		.nowrap {
			white-space: nowrap;
		}
		.ButtonAnchor {
			vertical-align: baseline;
		}
	}

	&.edit-zip-code {
		@apply tw-items-start;

		.deliveryForm {
			margin-top: 5px;

			.field-group {
				@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-start tw-items-center tw-mb-0;
			}

			label {
				@apply tw-pb-0;
				flex: 0 0 auto;
				margin-right: 5px;
			}

			input {
				flex: 1 1 0;
				max-width: 58px;
				margin-right: 5px;
			}

			button {
				flex: 0 0 auto;
				padding-left: 7px;
				padding-right: 7px;
			}

			.validation {
				flex: 0 0 100%;
				margin-top: 10px;
			}
		}
	}
}

@screen smOnly {
	.delivery-container {
		border-bottom: none;
	}
}
