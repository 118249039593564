

@media all {

	.Thumb {
		position: relative;
		margin: 0 0 10px;

		.Thumb-btn {
			&,
			&:link,
			&:visited {
				@apply tw-text-orange;
				font-size: 10px;
				padding: 5px;
				background: theme('colors.white.DEFAULT');
				position: absolute;
				bottom: 10px;
				right: 0;
				min-width: 45px;
				min-height: 40px;
				line-height: 1.1;
				box-shadow: theme('colors.gray.200') -3px 2px 2px -1px;
			}
		}
	}
}
