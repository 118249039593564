$opaque: 1;
$globalLayerModifier: 2000;
$frostyLayer: 5;
$frostyLayerGlobal: $frostyLayer * $globalLayerModifier;
$spinnerLayer: $frostyLayer * 2;
$spinnerLayerGlobal: $spinnerLayer * $globalLayerModifier;
// Spinner size and message height based on default styles.
$spinnerSize: 45px;
$messageHeight: 21px;
$transparent: 0.001;
$transitionSpeed: 200ms;

.MagicOverlay {
	@apply tw-select-none;
	&.is-global {
		@apply tw-fixed;
		z-index: $frostyLayerGlobal;
	}
}
.MagicOverlayFade-enter {
	opacity: 0.001;
	&.MagicOverlayFade-enter-active {
		opacity: $opaque;
		transition: opacity $transitionSpeed ease-in;
	}
}
.MagicOverlayFade-exit {
	opacity: $opaque;
	&.MagicOverlayFade-exit-active {
		opacity: $transparent;
		transition: opacity $transitionSpeed ease-out;
		transition-delay: 500ms;
	}
}
.MagicOverlayFade-exit-done {
	.MagicOverlay-frosty {
		opacity: $transparent;
		transition: opacity $transitionSpeed ease-out;
		transition-delay: 500ms;
	}
}
.MagicOverlay-message {
	@apply tw-font-semibold tw-pointer-events-none tw-absolute tw-left-0 tw-w-full tw-text-center tw-text-gray-200;
	top: calc(50% + #{$spinnerSize} / 2);
	opacity: $transparent;
	transition: opacity $transitionSpeed linear;
	z-index: $frostyLayer;
	&.no-spinner {
		top: calc(50% - #{$messageHeight} / 2);
	}
	&.is-global {
		@apply tw-fixed;
		z-index: $spinnerLayerGlobal;
	}
}
.MagicOverlay-messageText {
	@apply tw-leading-normal;
	background: theme('colors.white.DEFAULT');
	padding: 3px 10px;
}
.MagicOverlay-frosty {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: $frostyLayer;
	background: rgba(255, 255, 255, 0.5);
	&.is-global {
		position: fixed;
		z-index: $frostyLayerGlobal;
	}
}
.MagicOverlay--opaqueFrosty .MagicOverlay-frosty {
	background: rgba(255, 255, 255, 1);
}
.Spinner {
	pointer-events: none;
	position: absolute;
	width: $spinnerSize;
	height: $spinnerSize;
	top: 50%;
	left: 50%;
	z-index: $spinnerLayer;
	transition: opacity $transitionSpeed;
	opacity: $transparent;
	&.is-global {
		position: fixed;
		z-index: $spinnerLayerGlobal;
	}
	.Spinner-bar {
		@apply tw-bg-white tw-absolute;
		animation: Spinner-spin 1.2s linear infinite;
		border-radius: 5px;
		width: 20%;
		height: 7.8%;
		top: -3.9%;
		left: -10%;
	}
	.Spinner-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
@keyframes Spinner-spin {
	0% { opacity: 0.797; }
	100% { opacity: 0.197; }
}
