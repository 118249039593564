.MagicRecos.MagicRecos--interstitial {

	@apply tw-border-t-2 tw-p-0;
	padding: 10px 35px;

	.MagicRecos-title {
		margin: 0 0 31px -35px;
	}
	.MagicRecos-prevButton {
		left: -35px !important;
	}
	.MagicRecos-nextButton {
		right: -35px !important;
	}
	.MagicRecos-prevButton,
	.MagicRecos-nextButton {
		&.slick-disabled {
			@apply tw-opacity-25;
		}
	}
	.slick-slider {
		.slick-track {
			@apply tw-h-auto tw-flex tw-items-baseline;
		}
	}
}

.MagicRecos.MagicRecos--touch,
.MagicRecos.MagicRecos--touchDisableSlide {
	@apply tw-border-t tw-border-solid tw-border-gray-400 tw-bg-white tw-relative;
	padding: 10px;

	.MagicRecos-title {
		margin: 6px 0 0;
	}

	.row {
		margin-bottom: 33px;
	}

	.MagicRecos-touchViewport {
		@apply tw-overflow-x-auto tw-overflow-y-hidden tw-whitespace-nowrap;

		.item {
			display: inline-block;
			width: 75%;
			max-width: 240px;
			padding: 10px 0;
			vertical-align: top;
			white-space: normal;

			> a {
				display: block;
			}
		}
	}
}

.MagicRecos.MagicRecos--touchDisableSlide {
	@apply tw-px-0;
	.MagicRecos-touchViewport {
		@apply tw-whitespace-normal tw-flex tw-flex-wrap;
		margin: 0 0 0 -10px;
		.item {
			@apply tw-max-w-none;
			width: calc(50% - 10px);
			margin: 10px 0 0 10px;
		}
	}
}


