

@media all {

	.CardSeparator {
		> * {
			position: relative;
			&:not(:first-child):before {
				content: ' ';
				border-left: 1px solid theme('colors.gray.400');
				position: absolute;
				top: 0;
				bottom: 0;
			  	left: 0;
			}
		}
	}
}
