@media all {
	.Button {
		@include button-base;

		&:focus, &.focus {
			box-shadow: 0 0 4px theme('colors.gray.400');
		}

		@each $name, $color in $button-palette2 {
			&.Button--#{"" + $name} {
				@apply tw-text-white;
				background: $color;

				&:focus, &.focus,
				&:hover, &.hover {
					&:not(:disabled) {
						@apply tw-bg-white;
						color: $color;
						border-color: $color;
					}
				}

				&:focus, &.focus {
					box-shadow: 0 0 4px $color;
				}
			}
		}
	}
	.ButtonOutline {
		@include button-base;
		@apply tw-border-white tw-border;
	}
	.ButtonSecondary {
		@include button-base;
		@apply tw-bg-navy tw-text-white;

		&:focus, &.focus,
		&:hover {
			@apply tw-bg-white tw-text-navy tw-border-navy;
		}

		&:focus:not(.is-animating), &.focus {
			box-shadow: 0 0 4px theme('colors.navy.DEFAULT');
		}
	}
	.ButtonDark {
		@include button-base;
		padding-bottom: 7px;
		padding-top: 7px;

		&:focus, &.focus,
		&:hover {
			@apply tw-bg-black tw-border-white tw-text-white;
		}

		&:focus:not(.is-animating), &.focus {
			box-shadow: 0 0 4px theme('colors.gray.DEFAULT');
		}
	}
	.ButtonTertiary {
		@include button-base;
		@apply tw-text-base tw-no-underline;
		@screen md {
			@apply tw-text-sm;
		}

		&:focus, &.focus,
		&:hover, &.hover {
			@apply tw-bg-white tw-text-gray tw-border-gray tw-outline-none;
		}

		&:focus, &.focus {
			box-shadow: 0 0 4px theme('colors.gray.400');
		}

		@each $name, $color in $button-palette2 {
			&.ButtonTertiary--#{"" + $name} {
				@apply tw-text-white;
				background: $color;

				&:focus, &.focus,
				&:hover, &.hover {
					@apply tw-bg-white;
					color: $color;
					border-color: $color;
				}

				&:focus, &.focus {
					box-shadow: 0 0 4px $color;
				}
			}
		}
	}
	.ButtonSupport {
		@include button-base;
		@apply tw-font-normal tw-text-base;
		@screen md {
			@apply tw-text-sm;
		}
		padding: 5px;

		&:focus, &.focus,
		&:hover, &.hover {
			@apply tw-bg-white tw-text-gray tw-border-gray tw-outline-none;
		}

		&:focus:not(.is-animating), &.focus {
			box-shadow: 0 0 4px theme('colors.gray.100');
		}

		@each $name, $color in $button-palette2 {
			&.ButtonSupport--#{"" + $name} {
				@apply tw-text-white;
				background: $color;

				&:focus, &.focus,
				&:hover, &.hover {
					@apply tw-bg-white;
					color: $color;
					border-color: $color;
				}

				&:focus, &.focus {
					box-shadow: 0 0 4px $color;
				}
			}
		}
	}
	.ButtonAnchor {
		@include button-base;
		@apply tw-select-text tw-p-0 tw-min-h-0 tw-underline tw-text-base tw-border-transparent tw-font-normal;
		@screen md {
			@apply tw-text-sm;
		}
		// Font descenders in iOS Safari are cut off when line-height is 1.
		line-height: 1.2;
		background: none;
		vertical-align: baseline;
		text-decoration: underline !important;

		&[disabled]:not(.is-animating),
		&.is-disabled:not(.is-animating) {
			@apply tw-text-gray-400 tw-bg-white;
		}

		&:focus:not(.is-animating) {
			box-shadow: none;
			&.is-disabled {
				box-shadow: none;
			}
		}

		&:first-letter {
			text-transform: uppercase;
		}

		&,
		&:link,
		&:visited,
		&:active {
			@apply tw-text-blue;
		}

		&:focus, &.focus,
		&:hover, &.hover {
			@apply tw-bg-transparent tw-text-gray tw-border-transparent tw-shadow-none;
		}

		&:focus, &.focus {
			outline: 1px dotted theme('colors.gray.300');
			@apply tw-text-gray-100;
		}
		&.ButtonAnchor--noUnderline {
			text-decoration: none !important;

			&:focus, &.focus,
			&:hover, &.hover {
				text-decoration: underline !important;
			}
		}
	}

	.ButtonAnchor--red {
		&, &:link, &:visited, &:active {
			@apply tw-text-red;
		}

		&:focus, &.focus,
		&:hover, &.hover {
			@apply tw-text-blue;
		}
	}

	.ButtonAnchor--gray {
		&, &:link, &:visited, &:active {
			@apply tw-text-gray;
		}

		&:focus, &.focus,
		&:hover, &.hover {
			@apply tw-text-blue;
		}
	}

	@mixin ButtonArrow {
		@apply tw-bg-white tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-gray-100 tw-border;
		height: 24px !important;
		width: 24px !important;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			@apply tw-border-b tw-border-r tw-transform;
			content: '';
			height: 9px;
			width: 9px;
		}

		&:focus, &:hover {
			@apply tw-bg-gray-100;
			&:before {
				@apply tw-border-white;
			}
		}

		&:focus {
			outline: 1px dotted #757575;
			outline-offset: 2px;
			box-shadow: 0 0 1px #ccc;
		}

		&:disabled {
			@apply tw-border-gray-400;
			&:before {
				@apply tw-border-gray-400;
			}
			&:hover {
				@apply tw-border-gray-400 tw-bg-white;
			}
		}
	}

	.ButtonArrowDown {
		@include ButtonArrow();

		&:before {
			transform: rotate(45deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	.ButtonArrowLeft {
		@include ButtonArrow();

		&:before {
			transform: rotate(135deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	.ButtonArrowRight {
		@include ButtonArrow();

		&:before {
			transform: rotate(-45deg) translateX(-1px) translateY(-1px) !important;
		}
	}

	.ButtonArrowUp {
		@include ButtonArrow();

		&:before {
			transform: rotate(225deg) translateX(-1px) translateY(-1px) !important;
		}
	}


	.ButtonAddToCart {
		@include button-base;
		@apply tw-px-12;

		@screen smBsOnly {
			@apply tw-px-8;
		}
	}
	@keyframes Button-fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@keyframes Button-fade-out {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}

@screen smOnly {
	.Button--smallOnly {
		@include button-base;

		&:focus, &.focus {
			box-shadow: 0 0 4px theme('colors.gray.400');
		}

		@each $name, $color in $button-palette2 {
			&.Button--#{"" + $name} {
				@apply tw-text-white;
				background: $color;

				&:focus, &.focus,
				&:hover, &.hover {
					@apply tw-bg-white;
					color: $color;
					border-color: $color;
				}

				&:focus, &.focus {
					box-shadow: 0 0 4px $color;
				}
			}
		}
	}
	.ButtonSecondary--smallOnly {
		@include button-base;
		@apply tw-bg-navy tw-text-white;

		&:focus,
		&:hover {
			@apply tw-bg-white tw-text-navy tw-border-navy;
		}

		&:focus:not(.is-animating) {
			box-shadow: 0 0 4px theme('colors.navy.DEFAULT');
		}
	}
}
