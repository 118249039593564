@media screen {

	/*-----------------------------------------------------------
		Common styling and components for mobile and full site.

		TABLE OF CONTENTS:

		UTILITY CLASSES
		TABLES
		FOCUS STYLING
		FORM COMPONENTS
		NORMALIZATION
	-----------------------------------------------------------*/

	/*-----------------------------------------------------------
		UTILITY CLASSES
	-----------------------------------------------------------*/

	.hidden, .hide {
		display: none;
	}
	.stacked, .u-stacked--10 {
		margin-bottom: 10px !important;
	}
	.stacked-md, .u-stacked--20 {
		margin-bottom: 20px !important;
	}
	.u-stacked--25 {
	  margin-bottom: 25px !important;
	}
	.u-stacked--30 {
		margin-bottom: 30px !important;
	}
	.clipboard-data {
		position: absolute;
		left: -9999em;
	}

	/*-----------------------------------------------------------
		TABLES
	-----------------------------------------------------------*/
	// A simple table with no borders
	table {
		border-spacing: 0;
		border-collapse: collapse;

	}
	th, td {
		text-align: left;
	}

	/*-----------------------------------------------------------
		FOCUS STYLING
	-----------------------------------------------------------*/

	// link focus
	// TODO
	// - Refactor id's out of styling rules
	// - Then simplify all this crazyness.
	a {
		color: theme('colors.blue.DEFAULT');
		text-decoration: underline;
		background: none;
		font-size: inherit;
		font-family: inherit;
		// border: 0;
		display: inline;
		margin: 0;
		padding: 0;
		overflow: visible;

		// &::before {
		// 	content: ''
		// }

		span {
			font-family: inherit;
			font-size: inherit;
			color: inherit;
		}

		&:focus {
			color: theme('colors.gray.100');
		}

		&:hover {
			color: theme('colors.gray.100');
		}
	}
	button:not([class*='Button']),
	a:not([class*='Button']),
	.top-links a {
		&:focus {
			outline: 1px dotted theme('colors.gray.300');
			outline-offset: 1px;
			box-shadow: 0 0 4px theme('colors.gray.400');
		}
	}

	option:disabled {
		@apply tw-text-gray-400;
	}

	/*-----------------------------------------------------------
		FORM COMPONENTS
	-----------------------------------------------------------*/

	// Make buttons look like anchors.
	.button-as-anchor {
		@apply tw-border-0 tw-text-blue tw-underline tw-inline tw-m-0 tw-p-0 tw-cursor-pointer tw-overflow-visible;
		@apply hover:tw-text-gray;
		background: none;
		font-size: inherit;
		font-family: inherit;
		&::before {
			content: ''
		}
		span {
			font-family: inherit;
			font-size: inherit;
			color: inherit;
		}

		&:focus {
			color: theme('colors.gray.100');
		}
	}

	/*-----------------------------------------------------------
		NORMALIZATION
	-----------------------------------------------------------*/

	sup {
		top: -0.4em;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
	}
}
