@media all {
	.Pages {
		position: absolute;
		left: 0;
		&.Pages--noTransition {
			transition: none;
		}
		.Pages--toTheRight {
			position: absolute;
			left: 0;
		}
	}
	.Pages-positionContainer {
		@apply tw-overflow-hidden tw-absolute tw-justify-start;
		transition-duration: 300ms;
		transition-property: left;
		&.Pages-positionContainer--magicRecos {
			min-height: 210px;
		}
	}
	.Pages-page {
		@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-start tw-my-0 tw-mx-auto;
	}
}