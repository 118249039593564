

.Card {
	.Card-content {
		.Card-title {
			@apply tw-mb-2;
		}
		p, button {
			@apply tw-mb-3;
		}
	}
}