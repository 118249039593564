@import 'css/lib/tailwind-custom/_heading';

.Heading {
	@include Heading;
	&.Heading--1 {
		@include Heading--1;
	}
	&.Heading--2 {
		@include Heading--2;
	}
	&.Heading--3 {
		@include Heading--3;
	}
	&.Heading--4 {
		@include Heading--4;
	}
	&.Heading--5 {
		@include Heading--5;
	}
	&.Heading--6 {
		@include Heading--6;
	}
}
a.Heading {
	color: var(--anchor-color);
}
