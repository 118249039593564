@media all {
	.ScrollingContainer {
		overflow: hidden;
		margin: 0 -5px 10px -5px;

		ul {
			list-style: none;
			margin: 0 auto -20px auto;
			padding: 0 0 20px 0;
			overflow-x: auto;
			overflow-y: hidden;
			white-space: nowrap;
			-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
				display: none;
				background-color: transparent;
			}

			&.text-center {
				img {
					margin: 0 auto 10px auto;
				}
			}

			li {
				display: inline-block;
				vertical-align: top;
				white-space: normal;

				&:not(:first-child) {
					margin-left: 10px;
				}

				&:first-child {
					margin-left: 5px;
				}

				a {
					display: block;
				}

				img {
					display: block;
					margin-bottom: 10px;
					min-width: 1px;
					min-height: 1px;
				}
			}

			&.ScrollingContainer-maxWidth--130 {
				li {
					max-width: 130px;
				}

				li:last-child {
					max-width: 140px;

					p, span {
						margin-right: 10px;
					}
				}
			}

			&.ScrollingContainer-maxWidth--150 {
				li {
					max-width: 150px;
				}

				li:last-child {
					margin-right: 10px;
				}
			}

			&.ScrollingContainer-maxWidth--250 {
				li {
					max-width: 250px;
				}

				li:last-child {
					max-width: 260px;
					padding-right: 10px;

					p, span {
						margin-right: 10px;
					}
				}
			}

			&.ScrollingContainer-maxWidth--255 {
				li {
					max-width: 255px;
				}

				li:last-child {
					max-width: 265px;
					padding-right: 10px;

					p, span {
						margin-right: 10px;
					}
				}
			}

			&.ScrollingContainer-maxWidth--260 {
				li {
					max-width: 260px;
				}

				li:last-child {
					max-width: 270px;
					padding-right: 5px;

					p, span {
						margin-right: 5px;
					}
				}
			}

			&.ScrollingContainer-maxWidth--280 {
				li {
					max-width: 280px;
				}

				li:last-child {
					max-width: 285px;
					padding-right: 5px;

					p, span {
						margin-right: 5px;
					}
				}
			}
		}
	}

	.fade {
		animation-name: imgFadeIn;
		animation-duration: 1s;
	}

	@keyframes imgFadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
