$working-with-height: 43px;
$toolbar-menu-height: 55px;
$toolbar-height: $working-with-height + $toolbar-menu-height;
$toolbar-zi-base: 200;
$toolbar-zi-menus: $toolbar-zi-base - 10;

:root {
	--anchor-color: theme('colors.blue.DEFAULT');
	// This needs the "px" suffix so that calc() will work properly.
	--engage-toolbar-height: 0px;
	--toolbar-accent: theme('colors.navy.200');
	--toolbar-anim-speed: 200ms;
	--toolbar-zi-base: #{$toolbar-zi-base};
	--toolbar-zi-menus: #{$toolbar-zi-menus};
	// This needs the "px" suffix so that calc() will work properly.
	--devBeam-height: 0px;
	--devBeam-zi: #{$toolbar-zi-base + 10};
}

html {
	&.has-dev-beam {
		--devBeam-height: 42px;
	}
	&.is-engage-site {
		--engage-toolbar-height: 75px;
	}
}
