@media screen {
	.formz {
		.validation,
		.validation > span {
			@apply tw-text-red;
			margin: 6px 0 0;
		}
		.field-group {
			clear: left;
			margin-bottom: 15px;

			&.multi-entry-field-group > .field-group:nth-last-of-type(1) {
				margin-bottom: 7px;
			}

			.composite input[type="text"],
			.composite select {
				display: inline-block;
				vertical-align: top;

				&:not(:last-child) {
					margin-right: 15px;
				}
			}

			.composite .primaryPhone-field,
			.composite .secondaryPhone-field {
				width: 90px; // Have to leave enough room for IE's 'x' when focused.
			}
		}
		.password-container {
			@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-start tw-items-center;
		}

		.label-descriptor {
			@apply tw-block tw-text-gray-300;
		}
		label {
			&.disabled-field {
				opacity: 0.5;
			}
		}
		label + .label-descriptor {
			margin: -5px 0 5px;
			padding: 0;
		}
		.instruction {
			@apply tw-text-orange tw-block;
			margin-bottom: 15px;
		}
		.checkbox-field-group {
			@apply tw-flex tw-items-baseline;
			input {
				&:not([type="checkbox"]) {
					border: none;
					width: auto;
				}
			}

			label,
			.checkbox-label-group,
			.label-group {
				line-height: 1.4;
				padding-left: 5px;
				margin: 0 0 0 5px;
				font-weight: normal;
			}

			.checkbox-label-group,
			.label-group {
				& > label {
					padding: 0;
				}
			}
		}
		.required-field::after {
			@apply tw-text-orange;
			content: "*";
		}
		.required-legend {
			@apply tw-text-gray-300 tw-block tw-font-normal;
			margin-bottom: 10px;

			&:before {
				@apply tw-text-orange;
				content: "*";
			}
		}

		.required-legend-no-star {
			@apply tw-text-brown tw-block tw-font-normal tw-text-lg;
			margin-bottom: 10px;
		}

		.radio-options .field-group {
			position: relative;
			padding-left: 0px;
		}

		.radio-options {
			&.stacked-radio-options {
				.field-group {
					display: block;

					label {
						float: none;
					}
				}
			}

			.field-group {
				display: inline-block;
				margin: 5px 20px 0 0;
			}

			input {
				border: none;
				float: left;
				margin: 5px 5px 5px 1px;
			}
			label {
				float: right;
				font-weight: normal;
			}
		}

		.field-line {
			.field-group {
				display: inline-block;
				margin-right: 15px;
				vertical-align: top;
			}
			.field-group:last-child {
				margin-right: 0;
			}
		}

		.inline-group.show-password {
			@apply tw-flex;
			margin: 0 0 0 10px;
			label {
				padding-bottom: 0;
			}
		}

		label,
		button {
			&.show-password {
				padding: 5px;
				& + * {
					flex-basis: 100%;
				}
				svg {
					position: relative;
					top: 1px;
				}
				input {
					position: absolute;
					overflow: hidden;
					clip: rect(0 0 0 0);
					height: 1px; width: 1px;
					margin: -1px; padding: 0; border: 0;
				}
				// &:focus {
				// 	outline: 0;
				// 	box-shadow: 0;
				// }

				// &:focus-within {
				// 	svg {
				// 		outline: 1px dashed theme('colors.gray.300');
				// 		outline-offset: 1px;
				// 		box-shadow: 0 0 4px theme('colors.gray.400');
				// 	}
				// }
			}
		}

		.email-suggestion {
			@apply tw-text-red;
			margin-top: 4px;
			* {
				@apply tw-text-red;
			}
			a {
				font-style: italic;
			}
		}

		.field-and-dollar-sign {
			position: relative;
			input {
				text-align: right;
			}
			.dollar-sign {
				position: absolute;
				bottom: 8px;
				left: 5px;
			}
		}

		.help {
			@apply tw-bg-navy tw-text-white tw-inline-block tw-text-center tw-no-underline;
			border-radius: 9px;
			height: 18px;
			margin: 1px 0.75em 0;
			padding: 1px 0 0;
			width: 18px;
		}

		.validation-form {
			@apply tw-text-red;
			margin-bottom: 8px;
		}
	}
}
