@import 'css/lib/tailwind-custom/triangle';

@media screen {
	.pre-header-menu {
		flex: 0 0 100%;
		padding: 5px 0 16px;
		> ul > li > a {
			white-space: nowrap;
		}

		> ul {
			@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-center tw-m-0 tw-p-0;
			list-style: none;

			> li {
				padding: 0;
				margin-left: min(15px, 1.5%);
				margin-right: min(15px, 1.5%);
				position: relative;

				a {
					@apply tw-text-gray tw-block tw-no-underline tw-relative;
					font-size: 14px;
					@screen bsLgOnly {
						font-size: 15px;
					}
					@screen lg {
						font-size: 16px;
					}
					&::after {
						@apply tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-my-0 tw-mx-auto tw-w-0;
						border-top: 3px solid theme('colors.gray.DEFAULT');
						transition: width 0.2s ease-in-out;
						content: '';
						z-index: 151;
						bottom: -6px;
						@screen bsLgOnly {
							bottom: -5px
						}
						@screen lg {
							bottom: -3px;
						}
					}

					&:hover,
					&:focus {
						color: initial !important;
						text-decoration: none;
					}

					&:focus,
					&:hover {
						&::after {
							width: 100%;
						}
					}
					&:focus ~ button::before {
						opacity: 1;
					}
				}

				> button {
					background-color: transparent;
					border: none;
					display: block;
					height: 1px;
					left: 0;
					padding: 0;
					position: absolute;
					text-indent: -99999px;
					width: 100%;
					bottom: -6px;
					@screen bsLgOnly {
						bottom: -5px
					}
					@screen lg {
						bottom: -3px;
					}

					&:focus {
						&::before {
							opacity: 1;
						}
						&::after {
							width: 100% !important;
							opacity: 1;
						}
					}

					&::before {
						@apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
						content: '';
						@include triangle(8px, theme('colors.gray.DEFAULT'), down);
						transition: opacity 0.2s ease-in-out;;
						z-index: 152;
					}

					&::after {
						@apply tw-bottom-0 tw-m-0 tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
						border-top: 3px solid theme('colors.gray.DEFAULT');
						transition: width 0.2s ease-in-out;;
						content: '';
						z-index: 151;
					}

				}
				&:hover > button::after {
					// IE
					width: 100%;
				}
				&:focus-within > button::after {
					width: 100%;
				}

				&:focus-within > button::before {
					opacity: 1;
				}
			}
			li:hover {
				.pre-header-subMenu {
					visibility: visible;
				}
			}
		}
	}
	.pre-header-subMenu {
		@supports (display: grid) {
			display: grid;
		}
		transition: visibility 0s linear 0.4s;
		visibility: hidden;
		position: absolute;
		top: 23px;
		padding: 15px 0;
		z-index: 150;
		background: theme('colors.gray.600');
		border-top: 3px solid theme('colors.gray.400');
		left: -15px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);		
		&.showMenu {
			visibility: visible;
		}
		ul {
			display: grid;
			grid-row-gap: 10px;
			width: 210px;
			margin: 0;
			li {
				padding: 0 15px;
				a:hover, a:focus {
					@apply tw-underline;
					@apply tw-text-blue #{'!important'};
				}
				a::after {
					border-top: 0;
				}
			}
		}
	}
}

@screen lg {
	.pre-header-menu {
		ul li a {
			font-size: 16px;
		}
	}
}