@media screen {
	body {
		button {
			@apply tw-cursor-pointer;
			line-height: inherit;
		}
		fieldset {
			border: none;
			padding: 0;
			margin: 0;
			&[disabled] {
				label {
					@apply tw-opacity-50;
				}
			}
		}
		select {
			background: theme('colors.white.DEFAULT');
		}
		input,
		select,
		textarea {
			@apply tw-text-gray tw-border tw-border-solid tw-border-gray-400 tw-text-base tw-m-0 tw-max-w-full;
			@apply tw-shadow-none;
			&:focus {
				box-shadow: 0 0 8px theme('colors.gray.400');
				outline: none;
			}
			padding: 0 5px;
			&[disabled] {
				@apply tw-opacity-50;
			}
		}
		input {
			&[type='radio'],
			&[type='checkbox'] {
				&:focus {
					@apply tw-shadow-none;
					outline: 1px dotted theme('colors.gray.300');
					outline-offset: 2px;
				}
			}

			&[type='radio'] {
				border: none;
			}
		}
		select {
			// Having right padding messes up IE.
			padding-right: 0;
			height: 27px;
		}
		textarea {
			resize: none;
			padding: 5px 10px;
			line-height: 1.5;
		}
		::-webkit-input-placeholder {
			line-height: normal;
		}
		// Text-like inputs
		input:not([type='button']):not([type='checkbox']):not([type='color']):not([type='file']):not([type='image']):not([type='radio']):not([type='range']):not([type='reset']):not([type='submit']) {
			@apply tw-appearance-none tw-rounded-none;
			height: 27px;
		}
		label,
		legend {
			font-weight: bold;
			display: block;
			padding: 0 0 5px;
			cursor: pointer;
		}
		// Remove controls from Safari and Chrome
		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			@apply tw-appearance-none tw-m-0;
		}
		// Remove controls from Firefox
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}

	@screen md {
		body {
			input,
			select,
			textarea {
				@apply tw-text-sm;
			}
		}
	}
}
