#react-magic-modal .react-magic-modal-open .legacy-modal.tabbed-modal {
	.modal-content {
		border: none;
		padding-top: 0;
		margin: 0;
		.tabs-flex {
			padding: 0 20px;
			> *:first-child {
				margin-left: 0;
			}
		}
		.tab-content {
			border: none;
		}
	}
	.grey-round-tabs ul {
		padding: 0 2px;
		height: auto;
		li {
			margin: 0 0 0 4px;
			a,
			a.active-tab {
				font-size: 11px;
				font-weight: normal;
				width: auto;
				height: auto;
				padding: 3px 9px;
			}
		}
	}
}
