#react-magic-modal .react-magic-modal-open .legacy-modal {
	@apply tw-bg-white;
	z-index: 500;
	margin-bottom: 50px;
	position: relative;

	.modal-header {
		@apply tw-flex tw-flex-row-reverse tw-flex-nowrap tw-justify-between tw-items-center;

		.magic-modal-title {
			padding: 20px 15px 15px 20px;
			margin: 0;
			word-break: break-word;
			word-wrap: break-word;
		}

		.modal-close {
			border: none;
			background: none;
			margin: 15px 10px 0;
			padding: 5px 10px;
			cursor: pointer;
			z-index: 1;
			&:hover,
			&:focus {
				outline: 1px solid theme('colors.blue.DEFAULT');
				text-decoration: underline;
			}
			.modal-close-text {
				@apply tw-flex tw-text-brown;
				&::after {
					content: ' ';
					display: block;
					background: url(/img/ico/modal-close.svg) no-repeat 100% 50%;
					width: 12px;
					height: 24px;
					margin: 0 0 0 5px;
				}
			}
		}
	}
	.modal-content {
		border-top: 1px solid theme('colors.gray.400');
		margin: 0 20px;
		padding: 20px 0;
	}
	// Adjust for anchor bottom modal
	&.legacy-modal-anchor-bottom {
		bottom: 0;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
		position: fixed;
	}
	// Adjust for full bleed modal
	&.legacy-modal-full-bleed {
		.modal-header {
			.modal-close {
				margin: 5px;
				padding: 5px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.modal-content {
			margin-left: 0;
			margin-right: 0;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
		}
	}
	// Adjust for absence of a close button
	&.legacy-modal-no-close-button {
		.modal-header {
			@apply tw-flex-row;
		}
	}
	// Adjust for absence of a modal title
	&.legacy-modal-no-title {
		.modal-header {
			.magic-modal-title {
				padding: 0;
			}
		}
		.modal-content {
			border-top: none;
			padding-top: 5px;
		}
	}
	// Adjust for flush sides modal
	&.legacy-modal-no-side-margin {
		.modal-content {
			margin-left: 0;
			margin-right: 0;
		}
	}
	// Adjust for flush sides modal
	&.legacy-modal-reverse-close-button {
		.modal-header {
			.modal-close {
				@apply tw-bg-white tw-no-underline;
				margin: 10px !important;
				padding: 2px !important;
				text-indent: -99999px;
				.modal-close-text {
					&::after {
						@apply tw-text-navy tw-text-xl;
						content: 'X';
						background: none;
						height: 24px;
						line-height: 24px;
						margin: 0;
						text-indent: 0;
						width: 24px;
					}
				}
			}
		}
	}
	// Mobile template based on standard template for desktop.
	&.legacy-modal-mobile {
		.modal-header {
			@apply tw-flex-row tw-items-start;
			.magic-modal-title {
				padding: 35px 10px 10px;
			}
			.modal-close {
				margin: 5px;
				padding: 5px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.modal-content {
			margin: 0 10px;
			padding-top: 10px;
		}
		// Adjust for anchor bottom modal
		&.legacy-modal-anchor-bottom {
			left: 0;
			margin-left: 10px;
			margin-right: 10px;
		}
		// Adjust for drop shadow modal
		&.legacy-modal-drop-shadow {
			box-shadow: 0 3px 6px theme('colors.gray.DEFAULT');
		}
		// Adjust for full bleed modal on mobile
		&.legacy-modal-full-bleed {
			.modal-header {
				.modal-close {
					position: absolute !important;
				}
			}
			.modal-content {
				margin-left: 0;
				margin-right: 0;
				padding-bottom: 0 !important;
				padding-top: 0 !important;
			}
		}
		// Adjust for absence of a modal title on mobile
		&.legacy-modal-no-title {
			.modal-header {
				@apply tw-flex-row-reverse;
				.magic-modal-title {
					padding: 0;
				}
				.modal-close {
					position: static;
				}
			}
		}
		// Adjust for flush sides modal on mobile
		&.legacy-modal-no-side-margin {
			.modal-content {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	&.tabbed-modal {
		.modal-content {
			border: none;
			padding-top: 0;
			margin: 0;
			.tabs-flex {
				padding: 0 20px;
				> *:first-child {
					margin-left: 0;
				}
			}
			.tab-content {
				border: none;
			}
		}
		.grey-round-tabs ul {
			padding: 0 2px;
			height: auto;
			li {
				margin: 0 0 0 4px;
				a,
				a.active-tab {
					font-size: 11px;
					font-weight: normal;
					width: auto;
					height: auto;
					padding: 3px 9px;
				}
			}
		}
	}
}
