.contactUs-form {
	input[type="text"], input[type="email"] {
		width: 100%;
	}
	input[type="checkbox"] {
		@apply tw-shrink-0;
	}
	textarea {
		width: 100%;
		height: 100px;
	}
	:global .text-area-countdown {
		@apply tw-text-orange;
	}
	:global .sendCopy-field-group, :global .emailList-field-group {
		@apply tw-flex tw-flex-row tw-items-baseline;
		input[type="checkbox"] {
			@apply tw-mr-2;
		}
	}
	:global .emailList-label-contact-us-form {
		@apply tw-font-normal;
	}
}

.contactUs-call {
	@apply tw-no-underline tw-text-gray-200 tw-flex tw-items-end;
	img {
		@apply tw-mr-1 tw-mb-1;
	}
}

.contactUs-chat {
	&.contactUs-chat--available {
		@apply tw-text-blue tw-underline tw-cursor-pointer;
		img {
			@apply tw-mr-2 tw-mb-1;
		}
	}
	&.contactUs-chat--notAvailable {
		@apply tw-flex tw-items-start;
		img {
			@apply tw-mr-2 tw-mb-1 tw-mt-2;
		}
		.contactUs-status {
			@apply tw-flex tw-items-center tw-align-middle;
		}
		.contactUs-status:before {
			content: "•";
			@apply tw-text-orange tw-pr-1;
			font-size: 20px;
			position: relative;
			margin-top: -6px;
		}
	}
}

.contactUs-holidayHours {
	:global .site-message {
		@apply tw-bg-white tw-p-0;
		:global .site-secondary-message {
			p {
				@apply tw-block tw-text-red tw-text-left tw-text-lg tw-font-light;
				&:first-child {
					@apply tw-text-lg tw-font-semibold tw-mb-1;
				}
				span {
					@apply tw-font-normal tw-inline;
				}
			}
		}
	}
};

@screen md {
	.view-state-container.view-state-container-form .contactUs-form {
		@apply tw-w-8/12;
	}
}
